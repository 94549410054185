.weekSelector {
    position: fixed;
    top: 56px;
    z-index: 999;
    width: 100%;
    left: 0;
    right: 0;
    height: 80px;
    background: #fff;
    border-bottom: 1px solid #dadada;
    display: flex;
    overflow-x: scroll;
    overflow-y: hidden;
}

.dayListItem {
    width: 150px;
}