.weekContainerButton {
    height: 100%;
    margin: 0;
    min-width: 130px;
    max-width: 130px;
    border-right: 1px solid #adadad;
}

.weekContainerButton>*>h2 {
    font-size: 1rem;
    width: 100%;
    color: #000;
}

.weekContainerButton>*>p {
    color: #9a9a9a;
    font-size: 0.6rem;
}