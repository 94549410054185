.link {
    margin-top: 5px;
    margin-left: 5px;
    padding: 10px;
    border: 1px solid #ff8c00;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    font-weight: bold;
}

.link:hover {
    background: #ff8c00;
}

.link:active {
    background: #f8c795;
}

.canvasContainer {
    position: relative;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    display: flex;
    flex-grow: 1;
    padding: 16px;
    border: 1px solid #efefef;
}

.denseTable {
    margin: 0;
    padding:0;
    border: none;
    fontSize: 8px;
}

.canvasStyle {
    width: 100%;
    height: 100%;
}

.createIcon {
    width: 40px;
    height: 40px;
    opacity: 0.5;
    position: absolute;
    right: 16px;
    top: 16px;
}

.resetButton {
    position: absolute;
    bottom: 0;
    left: 50px;
    right: 50px;
}