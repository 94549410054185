.clockButton {
    bottom: 8px;
    width: 100%;
    display: flex;
    justify-content: center;
}

.clockButton>div {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.clockButton>*>p {
    font-size: 0.9rem;
    padding: 0;
    margin: 4px;
}

.clocking {
    color: green;
}

.not-clocking {
    color: red;
}